#footer-pt1 {
    background-color: #2C2C33;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-pt2-text {
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 20px;
    color: white;
}

#aviso{
    margin-left: 50px;
}

.terminos {
    margin-left: 20px;
}

#footer-pt2 {
    background-color: #212121;
}